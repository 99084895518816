import React from "react"

import { Text } from "../core/commonExports"
import { Input, InputContainer, PhoneNoInput, mandatoryField, InputLabel } from "./common";
import { Button } from "../core/Button";
import { Switch } from "../core/Switch";

import WAIcon from "../../assets/images/whatsapp_icon.svg"
import NoSpam from "../../assets/images/campaigns/icons/no-spam.svg"

const LeadDataCollection = ({
  companyName,
  setCompanyName,
  customerName,
  setCustomerName,
  mobile,
  setMobile,
  onFormSubmit,
  waUpdates,
  setWaUpdates,
  policyType,
  isPopup,
  formType,
  newTheme,
  quoteCtaProps,
  isSafetyPlan
}) => {
  const {label, uppercaseLabel} = quoteCtaProps
  let headerText = "Get an instant Quote today!"
  if (newTheme) {
    headerText = "Get an instant Quote in 1 minute"
  } else {
    if (
      (formType.includes("google") && isPopup) || 
      (formType === "website" && policyType !== "business-liability")
    ) {
      headerText = <>You are just 1 minute away from your <br/> instant quote</>
    }
  }

  if (isSafetyPlan) {
    headerText = "Secure your business NOW"
  }

  const disableLeadDataSubmit = 
    !companyName ||
    !customerName ||
    mobile?.length !== 10 

  return (
    <form style={{display: "flex", flexDirection: "column", width: "100%"}} onSubmit={onFormSubmit}>
      <Text style={{alignSelf: "center", textAlign: "center"}} fontSize="18px" mfontSize="16px" lineHeight="22.68px" color={isSafetyPlan ? "#333" : "#8668AD"} fontWeight="bold">
        {headerText}
      </Text>
      <InputContainer>
        <InputLabel htmlFor="frmName" desktopStyles={{marginBottom: "8px"}}>
          Your Name {mandatoryField}
        </InputLabel>
        <Input
          type="text"
          name="name"
          placeholder=""
          id="frmName"
          autocomplete="name"
          onChange={(e) => setCustomerName(e.target.value)}
          value={customerName}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="frmBusinessName" desktopStyles={{marginBottom: "8px"}}>
          Business Name (As per sign board) {mandatoryField}
        </InputLabel>
        <Input
          type="text"
          id="frmBusinessName"
          placeholder="Ex: Raju Kirana Store"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </InputContainer>
      <InputContainer style={{marginBottom: "10px"}}>
        <InputLabel htmlFor="frmPhoneNum" desktopStyles={{marginBottom: "8px"}}>
          Mobile Number {mandatoryField}
        </InputLabel>
        <PhoneNoInput
          placeholder=""
          onInput={setMobile}
          value={mobile}
          type="number"
          name="phone"
          id="frmPhoneNum"
          required
          autocomplete="tel"
          maxLength="10"
        />
        <div style={{display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "4px"}}>
          <NoSpam style={{width: "24px", height: "30px"}} />
          <Text fontSize="14px" mfontSize="12px" lineHeight="14px" mlineHeight="14px" color="#57BA70" style={{margin: "0"}}>
            We Promise, No Spam
          </Text>
        </div>
      </InputContainer>
      <div style={{display: "flex", alignItems: "center",alignSelf: "flex-start", gap: "0.5rem"}}>
        <Switch 
          switchId="wa-consent-switch"
          value={waUpdates}
          onChange={val => setWaUpdates(val)}
          label={<>
            <WAIcon />
            <Text fontSize="14px" mfontSize="12px" lineHeight="14px" mlineHeight="14px" style={{margin: "0"}}>
              Get updates on Whatsapp
            </Text>
          </>}
        />
      </div>
      <Button
        wide
        label={label || "Get a Quote"}
        type={newTheme ? "flatdesign" : "secondary"}
        uppercaseLabel={uppercaseLabel || false}
        onClick={onFormSubmit}
        disabled={disableLeadDataSubmit}
        style={{marginTop: '10px', alignSelf: "center"}}
      />
    </form>
  )
}

export default LeadDataCollection